import { createRouter, createWebHistory } from 'vue-router'
import  store  from '../store';



 


const router = createRouter({
  history: createWebHistory(),
  routes:  [
    {
      path: '/:id',
      name: 'Home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/reg/:id',
      name: 'Reg',
      component: () => import('../views/Reg.vue')
    },
    {
      path: '/profileuser',
      name: 'ProfileUser',
      component: () => import('../views/ProfileUser.vue'),
      meta: {rutaProtegida: true}
    },
    {
      path: '/selectoptions',
      name: 'SelectOptions',
      component: () => import('../views/SelectOptions.vue'),
      meta: {rutaProtegida: true}
    },
    {
      path: '/monto',
      name: 'monto',
      component: () => import('../views/Monto.vue')
    },

    {
      path: '/mydata',
      name: 'mydata',
      component: () => import('../views/MyData.vue')
    },

    {
      path: '/decode',
      name: 'decode',
      component: () => import('../views/DecodeQr.vue')
    },

    {
      path: '/recargar',
      name: 'recargar',
      component: () => import('../views/Recargar.vue')
    },

    {
      path: '/cash',
      name: 'cash',
      component: () => import('../views/MontoToCash.vue')
    },

    {
      path: '/adddta',
      name: 'adddta',
      component: () => import('../views/PersonalDta.vue')
    },

    {
      path: '/codeGen',
      name: 'codeGen',
      component: () => import('../views/CodeGenerate.vue')
    },
    {
      path: '/getOnGoogleplay',
      name: 'getOnGoogleplay',
      component: () => import('../views/GetOnGoogleplay.vue')
    },

    {
      path: '/getpoints',
      name: 'getpoints',
      component: () => import('../views/SelectoptionRecaga.vue')
    },


    {
      path: '/relocal',
      name: 'relocal',
      component: () => import('../views/RecargaLocal.vue')
    },

    {
      path: '/history',
      name: 'history',
      component: () => import('../views/History.vue')
    },

    
    {
      path: '/:pathMatch(.*)*',
      name: 'nofound',
      component: () => import('../views/HomeView.vue')
    },
    
   
    
  ]
  
})

router.beforeEach(((to,from,next) =>{
  if (to.meta.rutaProtegida) {
    if (store.getters.autUser) {

      next()
    }else{
      next('/home')
    }
  }else{
    next()
  }

}))

export default router
