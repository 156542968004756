import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {mapActions} from "vuex";
import {initializeApp} from "firebase/app";
import { doc, onSnapshot, setDoc,updateDoc, deleteField, getDoc } from "firebase/firestore";
import { getFirestore, serverTimestamp} from "firebase/firestore";
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import firebase from "firebase/app";



const firebaseConfig = {
    apiKey: "AIzaSyC6_bdIGk8MIAYaOIyUKhq_O246ieeSrgo",
    authDomain: "moneygo-b335c.firebaseapp.com",
    databaseURL: "https://moneygo-b335c-default-rtdb.firebaseio.com",
    projectId: "moneygo-b335c",
    storageBucket: "moneygo-b335c.appspot.com",
    messagingSenderId: "425049311811",
    appId: "1:425049311811:web:6401c9da55b07151d162f6"
  };

  const app = initializeApp(firebaseConfig);
 

createApp(App).use(store).use(router).mount('#app')





 