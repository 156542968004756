
import router from '@/router';
import { createStore } from 'vuex'
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, getDoc, onSnapshot, setDoc, updateDoc,Timestamp} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import moment from "moment";


 

export default createStore({
  state: {
    idUsuario: '',
    user:{  
      uid: "",
      token: "",
      notificationToken: '',
      tokui: '',
      nameUser: '',
          img: "",
          cel: '',
      cuentaB: {
          bankName: '',
          accountName: '',
          accountType: '',
          accountNumber: '',
          saldo: 0,
          disponible: 0,
          servicio: 0  //esta saldo es solo para pintarce pero en cada peticion sera actualizado desde la base de datos
        },
        
      categoryImg: "",
      groupImg: "",
      imgsReto: [],
      retodadata: [],
      idReto: '',
      idMyreto: '',
      modeAceptInvited: '',
      modeAceptInvitedUserId: '',
      modeAceptIdReto: '',
      option: 0 ,
      iduser: ''  
    
    },

    valorshareReto: '',
    retoOtherUserToMe: [],
    acepteds: [],
    intertime: 0,
    modeInvited: false, //define if user select your photo from your gallery
    invitedFriends: [],
    invitedFriendsCount: [],
    misRetos: [],
    misResInviteds: [],
    phototake: [],
    contentLocalNoti: [],
    changeImg: false,
    changingImg: false,
    changeData: '',
    front: false, //define if the photos has been taked with front camera
    offline: false,
    retosActivos: 0,
    invitaciones: 0,
    countInviteds: 0,
    mensaje: '',
    hour: '',
    ativeCound: 0,
    categoryQuestions: ''
   
  },
  getters:{

  },
  mutations: {
    

    async sendToken(state,payload) {
      const uid =  state.user.uid
      const db = getFirestore()
      try {
      state.user.notificationToken = payload
      if (uid) {
        await setDoc(doc(db, "tokens", uid),{
          token: payload
         });
        
        localStorage.setItem('IDdevice', payload)
      }
       
    
        
      } catch (error) {
        //console.log(error);
      }
    },


   async newtoken(state){
    const usuario ={
      nombre : '',
      email : '',
      uid: '',
      token: '',
      foto: '',
    } 
    
    if (localStorage.getItem('dataSesion')) {
      const dataUser = localStorage.getItem('dataSesion')
      const datos = JSON.parse(dataUser)
      usuario.uid = datos.uid
      usuario.email = datos.email
      usuario.nombre = datos.nombre.split(' ')[0]
      usuario.foto = datos.foto
    }
    try {     
      const res = await getAuth().currentUser.getIdToken(true)
      const dataDb = await res
      usuario.token = dataDb
      state.user.token = dataDb
      localStorage.setItem('dataSesion',JSON.stringify(usuario))
      } catch (error) {
       //console.log(error);
    }

    },

   async addmisRetos(state,payload){
      const datos = JSON.parse(localStorage.getItem("misRetos"))
      state.misRetos = []
      if (datos) {
            for(let i in datos){
              state.misRetos.push(datos[i])
            }  
          if (!state.misRetos.includes(payload)) {
              state.misRetos.push(payload)
              //console.log(state.misRetos);
              localStorage.setItem('misRetos',JSON.stringify(state.misRetos))
              try {
                const uid = state.user.uid
                const token = state.user.token
                const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/misRetos.json?auth=${token}`,{
                       method: 'PUT',
                       body: JSON.stringify(state.misRetos)   
                })
                const dataDb = await res.json()
                ;
              } catch (error) {
                //console.log(error);
              }
          }
 
      }else{
        state.misRetos.push(payload);
        localStorage.setItem('misRetos',JSON.stringify(state.misRetos))
        try {
          const uid = state.user.uid
          const token = state.user.token
          const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/misRetos.json?auth=${token}`,{
                 method: 'PUT',
                 body: JSON.stringify(state.misRetos)   
          })
          const dataDb = await res.json()
          ;
        } catch (error) {
          //console.log(error);
        }
        
      }
      
     
    },

    async addmisRetosInviteds(state,payload){
      if (payload!='Permission denied') {
        let ids1 = []
                for(let i in state.misResInviteds){
                    ids1.push(state.misResInviteds[i].id)
                }
                
                
                    if(ids1.includes(payload.id)){
                        //console.log('lo incluye')
                    }else{
                      state.misResInviteds.push(payload)  
                    }
                
                
                localStorage.setItem('misRetosInviteds',JSON.stringify(state.misResInviteds))
 
      }else{
        state.misResInviteds.push(payload);
        localStorage.setItem('misRetosInviteds',JSON.stringify(state.misResInviteds))
      }


      try {
        const uid = state.user.uid
        const token = state.user.token
        const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/Inviteds.json?auth=${token}`,{
               method: 'PUT',
               body: JSON.stringify(state.misResInviteds)   
        })
        const dataDb = await res.json()
        ;
      } catch (error) {
        //console.log(error);
      }
    }
      
    ,

   
    retoOtherUserToMe(state,payload){
      state.retoOtherUserToMe.push(payload)
      //console.log(state.retoOtherUserToMe);
    }, 


    async eliminar(state,payload){
     ;
      try {
        const idReto = state.user.idReto
        const token = state.user.token
        const uid = state.user.uid
        const db = getFirestore()
        let retos = []
        const datos = JSON.parse(localStorage.getItem("misRetos"))
       
        
          const res =  await  fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/retos/${uid}/${payload}.json?auth=${token}`,{
               method: 'DELETE'
          })

              const countlocal = []
              const local = JSON.parse(localStorage.getItem('localAcepteds'))
              if (local) {
                    if(local.length >1){
                      for(let i in local){ 
                        if (local[i] != payload) {
                          countlocal.push(local[i])
                        }    
                      }
                      localStorage.setItem('localAcepteds',JSON.stringify(countlocal))  
                    }else{
                      localStorage.setItem('localAcepteds',JSON.stringify(countlocal))  
                    }

              }else{
                
              }


         //functions borrar retencion
          await setDoc(doc(db, "retencion", uid),{
            [payload]:{
                del: true
            }
          },{merge:true})
                    
      
          
  
          
          
         
          const resDel = await res.json()
         
          if (!resDel) {
           
            
            
            for(let i in datos){
              
              if (datos[i].id == payload) {
                 if (datos.length == 1) {
                  retos = []
                  localStorage.setItem('misRetos',JSON.stringify(retos))
                  const uid = state.user.uid
                  const token = state.user.token
                  const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/misRetos.json?auth=${token}`,{
                        method: 'PUT',
                        body: JSON.stringify(retos)   
                  })
                  const dataDb = await res.json()
                  ;
                 }
              }else{
                retos.push(datos[i]) 
                localStorage.setItem('misRetos',JSON.stringify(retos))
               
              }
              
            }

              const uid = state.user.uid
              const token = state.user.token
              const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/misRetos.json?auth=${token}`,{
                    method: 'PUT',
                    body: JSON.stringify(retos)   
              })
              const dataDb = await res.json()
              ;
          }else{
            alert('no se ha eliminado')
          }

          
        

       
        
          

          //splice no funciona
        

        

        
        
       //ELIMINAR DEL LOCAL STORAGE

       

        
      } catch (error) {
        //console.log(error);
      }
    },

    changerutegroupImg(state, payload){
      state.categoryImg = payload;
   
    },

    temporalImg(state,payload){
      state.user.imgsReto = []
      state.user.imgsReto.push({url1: payload[0], url2: payload[1]})
    },
    
   async imgselect(state, payload){//agrega el grupo de imagen que elegi a un array  funcion de Retos
      state.user.imgsReto = []
      state.user.imgsReto.push({url1: payload.imagenes.url1, url2: payload.imagenes.url2, })
      if (payload.idReto) {
        state.user.idReto = payload.idReto; 
      }
      
  
     //enviar datos al cache

      // const cadena1 = payload.imagenes.url1.indexOf('?alt=')
      // const caden1a = payload.imagenes.url1.slice(0,cadena1)
      // const cadena2 = payload.imagenes.url2.indexOf('?alt=')
      // const caden2a = payload.imagenes.url2.slice(0,cadena2)
      // const token1 = payload.imagenes.url1.slice(cadena1)
      // const token2 = payload.imagenes.url2.slice(cadena2)

      // state.user.imgsReto.push({token1: token1, token2: token2})
      // //console.log(state.user.imgsReto.token1);
      // //console.log(state.user.imgsReto.token2);
      // caches.open('invited').then( cache =>{
      //   cache.addAll([
      //     caden1a,
      //     caden2a
      //   ])
      // })


     
      
    },

    saveIdReto(state, payload){
      state.user.retodadata = []
      state.user.idMyreto = payload
      state.user.idReto = payload
      state.user.retodadata.push(payload)
      ;
      
      
      
      
      
      
    },


    routeimg(state, payload){
      state.routeImg = payload
    },

    getUser(state, payload){  //aqui recogemos los primeros datos 
      state.user.nameUser = payload.nameUser,
      state.user.cel = payload.telephone  
    },

    async addPlayerToReto(state,payload){
      const token = state.user.token
      try {

       
          //console.log(payload.dataReto);
          if(payload.dataReto){
            const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/retos/${payload.dataReto.iduser}/${payload.dataReto.idreto}/player.json?auth=${token}`,{
            method: 'PATCH',
            body: JSON.stringify({
             fotoplayer: state.user.img,
             nameplayer: state.user.nameUser,
            })
         })
         
            //console.log('datos agregados');
            ;
          }else{
            const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/retos/${payload}/player.json?auth=${token}`,{
               method: 'PATCH',
               body: JSON.stringify({
                fotoplayer: state.user.img,
                nameplayer: state.user.nameUser,
               })
            })
            
          //console.log('datos agregados');
          ;
          }
        
        
      } catch (error) {
        //console.log(error);
      }
    },

    async addUser(state){ //aqui registramos el usuario en la base de datos despues de la confirmacion del telfono
      try {
        const id = state.user.cel
        const res =  await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/users/${id}.json`,{
              method: 'PUT',
              body: JSON.stringify(state.user)
          })

      const datadb = await res.json();
      //console.log(datadb);
      } catch (error) {
          //console.log(error);
      }
    },

   async loadData(state){
   
    try {
                
      const uid = state.user.uid
      const db = getFirestore()
      const docRef = doc(db, "usuarios", uid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const url = docSnap.data().foto
        const usuario ={
          nombre : '',
          email : '',
          uid: '',
          token: '',
          foto: url, 
      } 
    const dataUser = localStorage.getItem('dataSesion')
    const datos = JSON.parse(dataUser)
    usuario.uid = datos.uid
    usuario.token = datos.token
    usuario.email = datos.email
    usuario.nombre = docSnap.data().nombre.split(' ')[0]
    console.log(usuario.nombre);
    localStorage.setItem('dataSesion',JSON.stringify(usuario))
    state.user.img = url   
    state.changingImg = false
    state.user.nameUser = docSnap.data().nombre.split(' ')[0]
      } else {
        // doc.data() sera undefined en este caso
        //console.log("no se encuentra el documento");
      }
    } catch (error) {
        //console.log(error);
    }
    },



    bank(state){
      const db = getFirestore()
      const uid = state.user.uid
      if (uid) {
        const unsub = onSnapshot(doc(db,'saldo',uid),(doc) => { //snapshot informacion bancaria
          const data = doc.data()
            if (data) {
              if (data.account) {
                state.user.cuentaB.saldo = data.account.saldo
                state.user.cuentaB.disponible = data.account.disponible
                state.user.cuentaB.servicio =  data.account.servicio
                localStorage.setItem('bankInfo',JSON.stringify(data.account))
              }
            }else{
                const miSaldo = JSON.parse(localStorage.getItem('bankInfo'))
                state.user.cuentaB.saldo = miSaldo.saldo
            }
              
               
              
          });
      }
      
    },

    async loadPersonalData(state){
      try {
          const uid =  state.user.uid
          const db = getFirestore()
          const docRef = doc(db, "usuarios",uid);
          const docSnap = await getDoc(docRef);
          localStorage.setItem('idUsuario',docSnap.data().idUsuario)
          state.idUsuario = docSnap.data().idUsuario
          if (docSnap.data().account.personalData.Documentnumber) {
              localStorage.setItem('personlaData',JSON.stringify(docSnap.data().account.personalData))
              
              
          }
      } catch (error) {
          //console.log(error);
      }
      
  },


    //cargar datos de usuario del localstorage
    async loadDataUser(state){
      if (localStorage.getItem('dataSesion')) {
      const dataUser = localStorage.getItem('dataSesion')
      const datos = JSON.parse(dataUser)
      state.user.uid = datos.uid
      state.user.token = datos.token
      if (datos.nombre.includes(" ")) {
        state.user.nameUser  = datos.nombre.split(' ')[0];
      } else {
          state.user.nameUser  = datos.nombre;
      }
      state.user.img = datos.foto
      }
      if (localStorage.getItem('bankInfo')) {
        const miSaldo = JSON.parse(localStorage.getItem('bankInfo'))
        for(let i in miSaldo){
          state.user.cuentaB.saldo = miSaldo.saldo
        }
      }

      //cargar datos de banco
      try {
      
        

              
              if (localStorage.getItem('dataSesion')) {

                const local = []
                const uid = state.user.uid
                const token = state.user.token
                const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/misRetos.json?auth=${token}`)
                const dataDb = await res.json()
                if (dataDb) {
                 
                    

                  if (dataDb.error) {
                    //console.log(dataDb.error);
                  }else{
                    for(let i in dataDb){
                      local.push(dataDb[i])
                    }
                    if (local != '') {
                      localStorage.setItem('misRetos',JSON.stringify(local))
                    }
  
                  }
                    
                
                }
              }else{
                
              }
             

        
      } catch (error) {
        //console.log(error);
      }
      
      
    },

    
    localNoti(state,payload){
      state.contentLocalNoti = []
      state.contentLocalNoti.push(payload)
    },
    

    noti(state,payload){
      const options ={
      body: payload.body,
      icon: payload.icon?payload.icon:require('../assets/icon-72x72.png'),
      badge: payload.badge,
      tag: payload.tag,
      }
     
      
        if (Notification.permission == "granted"){
          navigator.serviceWorker.getRegistration()
          .then(reg =>{
          reg.showNotification(payload.title,options);
          //window.navigator.vibrate([200, 100, 200]);
          })
      }
      

     },

     async sendHistory(state,payload){ //enviar como historial cada accion que implique salida o entrada de dinero
      const db = getFirestore()
      const uid = state.user.uid
      const token = state.user.token 
      
      try {
        if(uid){
          //console.log(payload.id);
          await setDoc(doc(db, "History", uid),{
            
            [payload.restored?payload.id+'res':payload.id]:{
                  createAt: payload.createAt?payload.createAt:'',
                  id: payload.id?payload.id:'',
                  myres: payload.myres?payload.myres:'',
                  status: payload.status?payload.status:'',
                  type:payload.type?payload.type:'',
                  valor: payload.valor?payload.valor:'',
                  other: payload.other?payload.other:'',
                  mode: payload.mode?payload.mode:'',
                  restored: payload.restored?payload.restored:'',
                  otherFriends: payload.otherFriends?payload.otherFriends:'',
                  userdeleted: payload.userdeleted?payload.userdeleted:'',
                  idretoUsers: payload.idretoUsers?payload.idretoUsers:'',
                  iduser: payload.iduser?payload.iduser:'',
            }
            
          },{merge:true})
        }
        

      } catch (error) {
        //console.log(error);
      }
      
     },

     async getMyHistory(state){
      const uid = state.user.uid;
      const db = getFirestore()
      if (uid) {
        const docRef = doc(db, "History", uid);
      const docSnap = await getDoc(docRef);
      const datos = []
      for(let i in docSnap.data()){
          datos.push(docSnap.data()[i]);
      }

      localStorage.setItem('myHistory',JSON.stringify(datos))
      }
      
  },

    async  hour(state){
      const db = getFirestore()
      moment.locale('es');
      const createdAt = Timestamp.fromDate(new Date())
      const hour = moment(createdAt.seconds*1000).format('L')
   
      state.hour = hour
      if (hour) {
        localStorage.setItem('hour', JSON.stringify(hour))
        
      }else{
        state.hour = JSON.parse(localStorage.getItem("hour"))
      }
     

    },




  },
  actions: {
    temporalImg({commit}, payload){ //funcion de swiper
      commit('temporalImg', payload)   
    },
    selecPhoto({commit}, payload){
      commit('imgselect', payload)  
      
    },

    saveIdReto({commit}, payload){
      commit('saveIdReto', payload)
    },

     agregar({commit}, payload){
      commit('getUser', payload)
    },
    addUser({commit}){
      commit('addUser')
    },

    loadData({commit}){
      commit('loadData')
    },
    routeimg({commit}){
      commit('routeimg')
    },
    change({commit}, payload){
      commit('changerutegroupImg',payload)
    },
    loadDataUser({commit}){
      commit('loadDataUser')
    },
    addmisRetos({commit}, payload){
      commit('addmisRetos',payload)
    },
   
    eliminar({commit},payload){
      commit('eliminar',payload)
    },
    sendDataretoLocalstorage({commit},payload){
      commit('sendDataretoLocalstorage',payload)
    }
  },

  getters: {
    autUser(state){
      return !!state.user.nameUser
    }
  }

})


